export const articlePreviewData = [
  {
    link: 'couriers-of-the-year-2021',
    image: 'coty-awards-2021',
    title: 'Trexity announces Couriers of the year',
    type: 'announcement',
    date: 'April 18, 2022'
  },
  {
    link: 'hop-for-hope',
    image: 'hop-for-hope',
    title: 'Trexity partners with the Shepherds of Good Hope to make Easter a little less stressful',
    type: 'news',
    date: 'Mar 9, 2022'
  },
  {
    link: 'serving-up-customer-loyalty',
    image: 'bold-kettlemans',
    title: 'Serving up customer loyalty with subscriptions and rapid delivery.',
    type: 'news',
    date: 'Mar 1, 2022'
  },
  {
    link: 'helping-local-businesses-deliver',
    image: 'ottawa-trexity-helping-local',
    title: 'Local company Trexity is helping Ottawa businesses deliver 1,000 packages a day to residents.',
    type: 'news',
    date: 'Dec 20, 2021'
  },
  {
    link: 'elevate-your-christmas-dinner',
    image: 'chef-blackie',
    title: 'Local Chef Michael Blackie on his “Christmas Evening At Home”, offerings this holiday season.',
    type: 'news',
    date: 'Dec 16, 2021'
  },
  {
    link: 'ottawa-future-tech-sector',
    image: 'ottawa-tech',
    title: 'What does the future hold for Ottawa’s tech sector in 2022?',
    type: 'news',
    date: 'Dec 15, 2021'
  },
  {
    link: 'trexity-launches-in-winnipeg',
    image: 'christina-alok',
    title: 'Trexity same-day on demand delivery platform launches in Winnipeg',
    type: 'announcement',
    date: 'Dec 1, 2021'
  },
  {
    link: 'christmas-cheer-breakfast',
    image: 'christmas-cheer-breakfast',
    title: 'Christmas Cheer Breakfast sold out in-person, but plenty of goodies for virtual attendees',
    type: 'news',
    date: 'Nov 17, 2021'
  },
  {
    link: 'ctv-ottawa-curbside-pickup',
    image: 'ctv-evening-news-rob',
    title: 'Curbside Pickup features Trexity on CTV Ottawa evening news',
    type: 'news',
    date: 'May 12, 2021'
  },
  {
    link: 'ottawa-restaurateurs-pivot',
    image: 'pat-nicastro',
    title: 'Some Ottawa restaurateurs have pivoted to more pandemic-resistant businesses',
    type: 'news',
    date: 'May 10, 2021'
  },
  {
    link: 'trexity-canatrace-partner-for-covid-19',
    image: 'canatrace',
    title: 'Trexity and CANATRACE partner to screen all drivers for COVID-19 symptoms',
    type: 'news',
    date: 'March 18, 2021'
  },
  {
    link: 'ottawa-teams-up-to-boost-local',
    image: 'ottawa-bot-bia',
    title: 'Ottawa Board of Trade, BIA coalition team up on discount, delivery programs to boost local businesses',
    type: 'news',
    date: 'March 12, 2021'
  },
  {
    link: 'helping-local-businesses-during-lockdown',
    image: 'ctv-press-25000',
    title: '$25,000 across 100 new merchants for Ottawa businesses during lockdown',
    type: 'news',
    date: 'January 7, 2021'
  },
  {
    link: 'zapiet-and-trexity-partner-up',
    image: 'zapiet',
    title: 'Zapiet and Trexity partner up to offer Merchants same-day local delivery',
    type: 'announcement',
    date: 'November 23, 2020'
  },
  {
    link: 'tech-veteran-rob-woodbridge',
    image: 'rob-woodbridge',
    title: 'Shifting gears: Tech veteran Rob Woodbridge moves into C-suite at delivery platform Trexity',
    type: 'news',
    date: 'November 13, 2020'
  },
  {
    link: 'ottawa-company-delivers-help',
    image: 'ctv-overflow',
    title: 'Ottawa company delivers help to businesses with same-day drop-off',
    type: 'news',
    date: 'November 6, 2020'
  },
  {
    link: 'love-local-ottawa-trexity',
    image: 'love-local',
    title: 'Love Local: Trexity joins us with more the same day shipping service between cities',
    type: 'news',
    date: 'June 22, 2020'
  },
  {
    link: 'last-mile-solution-picks-up-speed',
    image: 'alok-ahuja',
    title: 'Shipping platform Trexity’s ’last-mile’ solution picks up speed with merchants',
    type: 'news',
    date: 'May 25, 2020'
  }
]
