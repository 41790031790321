import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const ArticlePreview = (props) => {
  return (
    <article>
      <Link to={`/press/${props.link}`}>
        <figure className='cover' style={{ backgroundImage: `url('/img/press/${props.image}.jpg')` }}></figure>
        <p className="lede">{props.title}</p>
      </Link>
      <div className="article-details">
        <span className={props.type}>{props.type}</span>
        <span>{props.date}</span>
      </div>
    </article>
  )
}

ArticlePreview.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string
}

export default ArticlePreview
