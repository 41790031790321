import React from 'react'
import Layout from '../../components/layout'
import Mailchimp from '../../components/mailchimp'
import ArticlePreview from '../../components/articlePreview'
import { articlePreviewData } from '../../api/articlePreviewData'

export default function Press () {
  return (
    <Layout>
      <section className="nm">
        <div className='row justify-center'>
          <div className='col text-center'>
            <div className="inner">
              <h1>The Trexity times</h1>
              <p>Everyone should take time to celebrate their progress, accomplishments, and victories—we sure do.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="press" className="nm">
        {articlePreviewData.map((data, key) => {
          return (
            <ArticlePreview
              key={key}
              link={data.link}
              image={data.image}
              title={data.title}
              type={data.type}
              date={data.date}
            />
          )
        })}
      </section>

      <section className="nm">
        <div className='row justify-center'>
          <div className='col'>
            <div className="inner">
              <blockquote className='quote'>
                We have an incredible team and we’re all very passionate about helping our local businesses. I want to empower them to not only beat Amazon but offer that same level of service to their customers that I know they can do.”
                <span>- Alok Ahuja</span>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section id='newsletter-signup'>
        <div className='row justify-center'>
          <div className='col w50'>
            <div className="inner">
              <h2>Stay in the loop</h2>
              <p>Sign up to our newsletter and we’ll let you know when we’ve hit a major milestone, partnership, or announcement.</p>
              <Mailchimp />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
